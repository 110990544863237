.reactMarkDown td, th {
    border: solid 1px rgba(255, 255, 255, 0.64);
}

.reactMarkDown tr {
    height: 50px;
    text-align: center;
}

.reactMarkDown table {
    margin: 30px 0;
    font-size: 13px;
}

.reactMarkDown table th, .reactMarkDown table tr:nth-child(even){
    background: rgba(146, 232, 229, 0.34);
}