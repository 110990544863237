@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spinner {
  to { transform: rotate(360deg); }
}

.spinner {
  display: inline-block;
  width: 1em; /* Größe des Spinners, angepasst an Ihren Bedarf */
  height: 1em; /* Größe des Spinners, angepasst an Ihren Bedarf */
  border: 2px solid currentColor; /* Nutzt die Textfarbe für den Spinner-Rahmen */
  border-right-color: transparent; /* Macht einen Teil des Spinners transparent */
  border-radius: 50%; /* Macht den Spinner kreisförmig */
  animation: spinner 1s linear infinite; /* Verwendet die definierte Animation */
}

/* Benutzerdefinierte Scrollbar für Webkit-Browser */
::-webkit-scrollbar {
  width: 8px; /* Schmalere Breite der Scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Transparenter Hintergrund des Scrollbar-Tracks */
  border-radius: 4px; /* Rundungen für den Track */
}

::-webkit-scrollbar-thumb {
  background-color: #4B5563; /* Farbe des Scrollbar-Handles */
  border-radius: 4px; /* Rundungen für das Scrollbar-Handle */
}

/* Für Firefox */
* {
  scrollbar-width: thin; /* 'thin' für schmalere Scrollbars */
  scrollbar-color: #4B5563 transparent; /* Farbe des Scrollbar-Handles und des Tracks */
}


@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fade-in 0.5s ease-in-out;
}

.circle-bg {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #5f96a1;
  stroke-width: 2.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}


